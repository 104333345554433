import React from 'react';
import { useNavigate } from 'react-router-dom';

// Komponent Hero sekcji
const Hero = ({ title, subtitle, backgroundImage }) => (
  <div className="relative text-white py-20 mt-16">
    <div 
      className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    />
    <div className="absolute inset-0 bg-black/60 z-0" />
    
    <div className="container mx-auto px-4 relative z-10">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 animate-fade-in">
          {title}
        </h1>
        <p className="text-xl text-gray-100 leading-relaxed">
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

// Komponent karty usługi
const ServiceCard = ({ title, icon, items }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <h3 className="text-xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className={`fas fa-${icon} text-emerald-500`} />
      {title}
    </h3>
    <ul className="space-y-3">
      {items.map((item, idx) => (
        <li key={idx} className="flex items-start text-gray-700 group">
          <span className="inline-block w-1.5 h-1.5 rounded-full bg-emerald-500 mt-2 mr-3 group-hover:scale-125 transition-transform" />
          <span className="flex-1">{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

// Komponent sekcji informacyjnej
const InfoSection = ({ title, children }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300">
    <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className="fas fa-certificate text-emerald-500" />
      {title}
    </h2>
    <div className="space-y-4 text-gray-700 leading-relaxed">
      {children}
    </div>
  </div>
);

const ElectricalMeasurements = () => {
  const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate('/#kontakt');
    setTimeout(() => {
      document.getElementById('kontakt')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const services = [
    {
      title: "Elektrownie fotowoltaiczne",
      icon: "solar-panel",
      items: [
        "Pomiary po stronie DC (napięcie i prąd w stringach)",
        "Analiza sprawności paneli fotowoltaicznych",
        "Lokalizacja uszkodzeń i analiza strat",
        "Weryfikacja parametrów falowników (strona AC)",
        "Pomiary rezystancji izolacji",
        "Pomiary impedancji pętli zwarcia",
        "Badania uziemienia",
        "Termowizja modułów i złączy"
      ]
    },
    {
      title: "Obiekty przemysłowe i biurowe",
      icon: "industry",
      items: [
        "Badania instalacji elektrycznych",
        "Pomiary rezystancji izolacji przewodów",
        "Weryfikacja zabezpieczeń",
        "Ocena stanu rozdzielnic",
        "Audyty efektywności energetycznej"
      ]
    },
    {
      title: "Gospodarstwa domowe",
      icon: "home",
      items: [
        "Kontrola ciągłości przewodów ochronnych",
        "Testy wyłączników różnicowoprądowych",
        "Pomiary rezystancji izolacji",
        "Badanie systemów odgromowych",
        "Diagnostyka awarii elektrycznych"
      ]
    }
  ];

  return (
    <div className="bg-gray-50">
      <Hero 
        title="Pomiary Elektryczne"
        subtitle="Profesjonalne usługi pomiarów elektrycznych dla elektrowni fotowoltaicznych, 
                 firm i gospodarstw domowych."
        backgroundImage="https://i.imgur.com/s6o2GfG.jpeg"
      />

      <div className="container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto mb-16">
          <p className="text-gray-700 text-lg text-justify leading-relaxed">
            Zapewniamy kompleksowe i profesjonalne usługi pomiarów elektrycznych dla elektrowni 
            fotowoltaicznych, firm, gospodarstw domowych oraz innych obiektów. Naszym priorytetem 
            jest precyzja, bezpieczeństwo i zgodność z obowiązującymi normami.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="max-w-3xl mx-auto">
          <InfoSection title="Nasza Gwarancja">
            <p>
              Dostarczamy pełną dokumentację z przeprowadzonych pomiarów, wydajemy certyfikaty 
              zgodności z normami PN-EN oraz obowiązującymi przepisami, a także przygotowujemy 
              raporty dla organów nadzoru budowlanego i ubezpieczycieli.
            </p>
            <p>
              Nasz zespół składa się z wykwalifikowanych elektryków z bogatym doświadczeniem 
              w projektach zarówno małych, jak i dużych. Dysponujemy nowoczesnym sprzętem 
              pomiarowym, który gwarantuje najwyższą dokładność.
            </p>
          </InfoSection>

          <div className="text-center mt-16">
            <p className="text-gray-700 mb-8 text-lg">
              Zapraszamy do współpracy i zachęcamy do kontaktu w celu uzyskania indywidualnej 
              wyceny pomiarów dostosowanej do Państwa potrzeb.
            </p>
            <button
              onClick={handleContactClick}
              className="px-8 py-4 bg-emerald-500 text-white font-semibold rounded-full 
                       hover:bg-emerald-600 transition-all duration-300 transform hover:-translate-y-1
                       focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              Skontaktuj się z nami
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricalMeasurements;