import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  // Własna walidacja zamiast pattern attribute
  const validateForm = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9+\s-]{9,}$/;

    if (!formData.name || formData.name.length < 3) {
      setSubmitStatus('error-name');
      return false;
    }

    if (!emailRegex.test(formData.email)) {
      setSubmitStatus('error-email');
      return false;
    }

    if (!phoneRegex.test(formData.phone)) {
      setSubmitStatus('error-phone');
      return false;
    }

    if (!formData.message || formData.message.length < 10) {
      setSubmitStatus('error-message');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus('');

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', phone: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Reset status when user starts typing
    setSubmitStatus('');
  };

  const getErrorMessage = () => {
    switch (submitStatus) {
      case 'error-name':
        return 'Proszę podać poprawne imię i nazwisko (min. 3 znaki)';
      case 'error-email':
        return 'Proszę podać poprawny adres email';
      case 'error-phone':
        return 'Proszę podać poprawny numer telefonu (min. 9 znaków)';
      case 'error-message':
        return 'Proszę wpisać wiadomość (min. 10 znaków)';
      case 'error':
        return 'Wystąpił błąd podczas wysyłania wiadomości. Spróbuj ponownie później.';
      default:
        return '';
    }
  };

  return (
    <section className="section bg-primary text-white relative" id="kontakt">
      <div className="container mx-auto px-8">
        <h2 className="section-title text-white" data-aos="fade-up">
          Skontaktuj się z Nami
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-10 border border-white/10" data-aos="fade-right">
            <div className="text-center mb-10">
              <i className="fas fa-headset text-5xl text-accent mb-4"></i>
              <h3 className="text-2xl font-bold">Jesteśmy do Twojej dyspozycji</h3>
            </div>

            <div className="space-y-4">
              <div className="bg-white/5 rounded-xl p-5 transform hover:translate-x-2 transition-transform duration-300">
                <span className="text-sm text-accent font-semibold block mb-2">TELEFON</span>
                <div className="flex flex-col gap-2">
                  <a href="tel:+48736353906" className="flex items-center gap-4 hover:text-accent transition-colors">
                    <i className="fas fa-phone text-accent"></i>
                    <span>+48 736 353 906</span>
                  </a>
                  <a href="tel:+48736353907" className="flex items-center gap-4 hover:text-accent transition-colors">
                    <i className="fas fa-phone text-accent"></i>
                    <span>+48 736 353 907</span>
                  </a>
                </div>
              </div>

              <div className="bg-white/5 rounded-xl p-5 transform hover:translate-x-2 transition-transform duration-300">
                <span className="text-sm text-accent font-semibold block mb-2">EMAIL</span>
                <a href="mailto:biuro@farmserwis.pl" className="flex items-center gap-4 hover:text-accent transition-colors">
                  <i className="fas fa-envelope text-accent"></i>
                  <span>biuro@farmserwis.pl</span>
                </a>
              </div>

              <div className="bg-white/5 rounded-xl p-5 transform hover:translate-x-2 transition-transform duration-300">
                <span className="text-sm text-accent font-semibold block mb-2">CZAS REAKCJI</span>
                <div className="flex items-center gap-4">
                  <i className="fas fa-clock text-accent"></i>
                  <span>do 2 godzin</span>
                </div>
              </div>

              <div className="bg-white/5 rounded-xl p-5 transform hover:translate-x-2 transition-transform duration-300">
                <span className="text-sm text-accent font-semibold block mb-2">OBSZAR DZIAŁANIA</span>
                <div className="flex items-center gap-4">
                  <i className="fas fa-map-marker-alt text-accent"></i>
                  <span>Cała Polska</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-10 border border-white/10" data-aos="fade-left">
            {submitStatus === 'success' && (
              <div className="mb-4 p-4 bg-green-500/20 border border-green-500/50 rounded-lg text-white">
                Wiadomość została wysłana pomyślnie!
              </div>
            )}
            {submitStatus && submitStatus !== 'success' && (
              <div className="mb-4 p-4 bg-red-500/20 border border-red-500/50 rounded-lg text-white">
                {getErrorMessage()}
              </div>
            )}
            
            <form className="space-y-4" onSubmit={handleSubmit} noValidate>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Imię i Nazwisko"
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/70 focus:border-accent focus:bg-white/10 transition-all duration-300"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/70 focus:border-accent focus:bg-white/10 transition-all duration-300"
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Telefon"
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/70 focus:border-accent focus:bg-white/10 transition-all duration-300"
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Wiadomość"
                rows="4"
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/70 focus:border-accent focus:bg-white/10 transition-all duration-300 resize-none"
              ></textarea>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full px-8 py-4 bg-accent text-white font-semibold rounded-lg hover:bg-accent-hover transform hover:-translate-y-1 transition-all duration-300 flex items-center justify-center gap-2 group disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <>
                    <i className="fas fa-spinner fa-spin" />
                    Wysyłanie...
                  </>
                ) : (
                  <>
                    Wyślij wiadomość
                    <i className="fas fa-paper-plane transform group-hover:translate-x-1 transition-transform duration-300"></i>
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
