import React from "react";

const Services = () => {
  const services = [
    {
      icon: "fas fa-file-alt",
      title: "Dokumentacja techniczno-prawna",
      description: "Kompleksowa obsługa dokumentacji techniczno-prawnej, w tym warunki zabudowy, przyłączenia oraz pozwolenia na budowę i użytkowanie.",
      link: "/uslugi/dokumentacja",
    },
    {
      icon: "fas fa-plug",
      title: "Instalacje elektryczne",
      description: "Profesjonalne instalacje elektryczne, w tym podłączenia AC i DC, konfiguracja oraz zdalny dostęp do systemów.",
      link: "/uslugi/instalacje-elektryczne",
    },
    {
      icon: "fas fa-bolt",
      title: "Pomiary elektryczne",
      description: "Profesjonalne pomiary elektryczne dla elektrowni fotowoltaicznych, firm i gospodarstw domowych. Zapewniamy precyzję i bezpieczeństwo.",
      link: "/uslugi/pomiary-elektryczne",
    },
    {
      icon: "fas fa-shield-alt",
      title: "Zabezpieczenia",
      description: "Zaawansowane systemy zabezpieczeń, w tym monitoring wizyjny, alarmy oraz bariery wejść.",
      link: "/uslugi/zabezpieczenia",
    },
    {
      icon: "fas fa-solar-panel",
      title: "Serwis PV",
      description: "Kompleksowy serwis fotowoltaiczny, obejmujący stronę AC i DC, inwertery, SmartLoggery oraz systemy CCTV.",
      link: "/uslugi/serwis-pv",
    },
    {
      icon: "fas fa-tower-cell",
      title: "Mobilne wieże CCTV",
      description: "Oferujemy wynajem, sprzedaż i transport mobilnych wież CCTV dla potrzeb monitoringu tymczasowego i stałego.",
      link: "/uslugi/wieze-cctv",
    },
  ];

  return (
    <section className="py-12 bg-light" id="uslugi">
      <div className="container mx-auto px-8">
        <h2 className="section-title text-center text-primary mb-8" data-aos="fade-up">
          Nasze Usługi
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <div className="text-center">
                <i className={`${service.icon} text-4xl text-accent mb-4`} />
                <h3 className="text-2xl font-bold text-primary mb-4">{service.title}</h3>
                <p className="text-text mb-6">{service.description}</p>
                <a
                  href={service.link}
                  className="px-6 py-2 bg-accent text-white font-semibold rounded-full hover:bg-accent-hover transition-colors duration-300"
                >
                  Czytaj więcej
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;