import React, { useState } from 'react';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Jak często należy serwisować farmę fotowoltaiczną?",
      answer: "Regularne przeglądy powinny odbywać się co najmniej dwa razy w roku. Dodatkowo, zalecamy monitoring ciągły oraz inspekcje po wystąpieniu ekstremalnych warunków pogodowych."
    },
    {
      question: "Jaki jest czas reakcji na zgłoszenie awarii?",
      answer: "Standardowy czas reakcji to maksymalnie 2 godziny od momentu zgłoszenia. W przypadku krytycznych awarii, nasz zespół jest w stanie dotrzeć na miejsce w ciągu 4 godzin."
    },
    {
      question: "Jakie są koszty regularnego serwisu?",
      answer: "Koszty serwisu są ustalane indywidualnie w zależności od wielkości instalacji, zakresu usług oraz częstotliwości przeglądów. Oferujemy elastyczne pakiety serwisowe dopasowane do potrzeb klienta."
    },
    {
      question: "Czy świadczycie usługi w całej Polsce?",
      answer: "Tak, działamy na terenie całej Polski. Posiadamy mobilne zespoły serwisowe gotowe do szybkiej reakcji niezależnie od lokalizacji farmy fotowoltaicznej."
    }
  ];

  return (
    <section className="section bg-light" id="faq">
      <div className="container mx-auto px-8">
        <h2 className="section-title" data-aos="fade-up">
          Często Zadawane Pytania
        </h2>
        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="mb-4"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <button
                className="w-full bg-white p-6 rounded-lg shadow hover:shadow-lg transition-all duration-300 flex justify-between items-center"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <span className="text-lg font-semibold text-primary text-left">{faq.question}</span>
                <i className={`fas fa-chevron-down text-accent transition-transform duration-300 ${
                  openIndex === index ? 'rotate-180' : ''
                }`} />
              </button>
              <div className={`bg-white px-6 overflow-hidden transition-all duration-300 rounded-b-lg ${
                openIndex === index ? 'max-h-96 py-6' : 'max-h-0'
              }`}>
                <p className="text-text">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;