import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  const handleNavigateWithHash = (hash) => {
    if (window.location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        window.location.hash = hash;
      }, 100);
    } else {
      window.location.hash = hash;
    }
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ${
        isScrolled
          ? "bg-white shadow-md py-3"
          : "bg-[#0f172a]/80 backdrop-blur-lg py-5"
      }`}
    >
      {/* Główna nawigacja */}
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="flex items-center justify-between">
          <a href="/" className="flex items-center gap-3 text-2xl font-bold">
            <i className="fas fa-solar-panel text-[#22c55e]" />
            <span className={isScrolled ? "text-gray-800" : "text-white"}>
              FARMSERWIS
            </span>
          </a>

          <div className="hidden lg:flex items-center gap-8">
            <div className="flex items-center gap-8">
              <a
                href="/"
                className={`hover:text-[#22c55e] transition-colors text-sm ${
                  isScrolled ? "text-gray-800" : "text-white"
                }`}
              >
                Strona Główna
              </a>
              <a
                href="/o-nas"
                className={`hover:text-[#22c55e] transition-colors text-sm ${
                  isScrolled ? "text-gray-800" : "text-white"
                }`}
              >
                O nas
              </a>
              <button
                onClick={() => handleNavigateWithHash("uslugi")}
                className={`hover:text-[#22c55e] transition-colors text-sm ${
                  isScrolled ? "text-gray-800" : "text-white"
                }`}
              >
                Usługi
              </button>
            </div>
            <button
              onClick={() => handleNavigateWithHash("kontakt")}
              className="px-6 py-2.5 bg-[#22c55e] hover:bg-[#16a34a] text-white text-sm font-medium rounded-full transition-colors"
            >
              Kontakt
            </button>
          </div>

          <button
            className="lg:hidden p-2 rounded-lg hover:bg-gray-100/10"
            onClick={() => setIsMenuOpen(true)}
          >
            <i
              className={`fas fa-bars text-xl ${
                isScrolled ? "text-gray-800" : "text-white"
              }`}
            />
          </button>
        </div>
      </div>

      {/* Menu mobilne - z pełnym tłem */}
      <div
        className={`fixed top-0 right-0 bottom-0 h-screen w-full sm:w-[300px] transform transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } lg:hidden z-50`}
        style={{
          background: '#0f172a',
          backgroundImage: 'none'
        }}
      >
        <div className="h-full flex flex-col" style={{ background: '#0f172a' }}>
          {/* Nagłówek menu */}
          <div className="bg-[#0f172a] p-4 flex items-center justify-between border-b border-gray-800">
            <div className="flex items-center gap-3">
              <i className="fas fa-solar-panel text-[#22c55e] text-xl" />
              <span className="text-white text-xl font-bold">FarmSerwis</span>
            </div>
            <button
              className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              <i className="fas fa-times text-white text-xl" />
            </button>
          </div>

          {/* Lista menu */}
          <div className="flex-1 overflow-y-auto bg-[#0f172a] p-6">
            <div className="flex flex-col gap-2">
              <a
                href="/"
                className="p-4 text-white hover:bg-[#22c55e] rounded-lg transition-colors flex items-center gap-3 bg-[#0f172a]"
                onClick={() => setIsMenuOpen(false)}
              >
                <i className="fas fa-home w-6" />
                <span>Strona Główna</span>
              </a>

              <a
                href="/o-nas"
                className="p-4 text-white hover:bg-[#22c55e] rounded-lg transition-colors flex items-center gap-3 bg-[#0f172a]"
                onClick={() => setIsMenuOpen(false)}
              >
                <i className="fas fa-info-circle w-6" />
                <span>O nas</span>
              </a>

              <button
                onClick={() => {
                  handleNavigateWithHash("uslugi");
                  setIsMenuOpen(false);
                }}
                className="p-4 text-white hover:bg-[#22c55e] rounded-lg transition-colors flex items-center gap-3 w-full text-left bg-[#0f172a]"
              >
                <i className="fas fa-tools w-6" />
                <span>Usługi</span>
              </button>

              <button
                onClick={() => {
                  handleNavigateWithHash("aktualnosci");
                  setIsMenuOpen(false);
                }}
                className="p-4 text-white hover:bg-[#22c55e] rounded-lg transition-colors flex items-center gap-3 w-full text-left bg-[#0f172a]"
              >
                <i className="fas fa-newspaper w-6" />
                <span>Aktualności</span>
              </button>
            </div>
          </div>

          {/* Przycisk kontaktu na dole */}
          <div className="bg-[#0f172a] p-6 border-t border-gray-800">
            <button
              onClick={() => {
                handleNavigateWithHash("kontakt");
                setIsMenuOpen(false);
              }}
              className="w-full p-4 bg-[#22c55e] hover:bg-[#16a34a] text-white rounded-lg transition-colors flex items-center justify-center gap-2"
            >
              <i className="fas fa-envelope" />
              <span>Kontakt</span>
            </button>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 lg:hidden z-40"
          onClick={() => setIsMenuOpen(false)}
        />
      )}
    </nav>
  );
};

export default Navbar;