import React from 'react';
import { useNavigate } from 'react-router-dom';
import Services from '../components/sections/Services';
import About from '../components/sections/About';

const AboutPage = () => {
  const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate('/');
    setTimeout(() => {
      const element = document.getElementById('kontakt');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <section className="section bg-light py-20" id="o-nas">
      <div className="container mx-auto px-8">
        <h2 className="section-title text-center text-primary mb-12" data-aos="fade-up">
          O nas
        </h2>

        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-text mb-8">
            Jesteśmy specjalistami w serwisowaniu farm fotowoltaicznych, zapewniając niezawodne wsparcie techniczne i długotrwałą efektywność instalacji.
            Nasza misja to dbałość o optymalną wydajność systemów fotowoltaicznych poprzez regularne przeglądy, naprawy i zaawansowane monitorowanie.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Sekcja: Nasza wizja */}
          <div className="bg-white p-8 rounded-lg shadow-lg" data-aos="fade-up">
            <h3 className="text-2xl font-bold text-primary mb-6">Nasza wizja</h3>
            <p className="text-text">
              Chcemy być zaufanym partnerem w utrzymaniu najwyższej jakości pracy farm fotowoltaicznych, przyczyniając się do stabilnego rozwoju rynku energii odnawialnej.
              Wierzymy, że nasza praca przekłada się na bardziej zrównoważoną przyszłość.
            </p>
          </div>

          {/* Sekcja: Dlaczego my? */}
          <div className="bg-white p-8 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="100">
            <h3 className="text-2xl font-bold text-primary mb-6">Dlaczego my?</h3>
            <ul className="space-y-4">
              <li className="text-text">
                <strong>Wykwalifikowanie:</strong> Nasz zespół składa się z wykwalifikowanych specjalistów posiadających wykształcenie w dziedzinie odnawialnych źródeł energii.
              </li>
              <li className="text-text">
                <strong>Doświadczenie:</strong> Od lat specjalizujemy się w serwisowaniu farm fotowoltaicznych różnej skali i typu.
              </li>
              <li className="text-text">
                <strong>Kompleksowe usługi:</strong> Zapewniamy pełne wsparcie serwisowe - od bieżących przeglądów po usuwanie usterek i monitorowanie pracy instalacji.
              </li>
              <li className="text-text">
                <strong>Technologia:</strong> Korzystamy z nowoczesnych narzędzi diagnostycznych i systemów monitorowania, aby szybko identyfikować i rozwiązywać problemy.
              </li>
              <li className="text-text">
                <strong>Zaangażowanie:</strong> Nasz zespół to ludzie z pasją do technologii odnawialnych, gotowi sprostać każdemu wyzwaniu.
              </li>
            </ul>
          </div>
        </div>

        {/* Sekcja: Nasze wartości */}
        <div className="mt-16">
          <h3 className="text-2xl font-bold text-primary text-center mb-8" data-aos="fade-up">
            Nasze wartości
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center" data-aos="fade-up">
              <h4 className="text-xl font-bold text-primary mb-4">Efektywność</h4>
              <p className="text-text">Dbamy o to, by Twoja instalacja fotowoltaiczna zawsze pracowała na najwyższych obrotach.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center" data-aos="fade-up" data-aos-delay="100">
              <h4 className="text-xl font-bold text-primary mb-4">Transparentność</h4>
              <p className="text-text">Nasza współpraca jest oparta na jasnych zasadach i rzetelnej komunikacji.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center" data-aos="fade-up" data-aos-delay="200">
              <h4 className="text-xl font-bold text-primary mb-4">Jakość</h4>
              <p className="text-text">Gwarantujemy najwyższy poziom serwisu i długowieczność instalacji.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center" data-aos="fade-up" data-aos-delay="300">
              <h4 className="text-xl font-bold text-primary mb-4">Odpowiedzialność</h4>
              <p className="text-text">Rozumiemy, że nasza praca ma bezpośredni wpływ na efektywność Twoich inwestycji oraz ochronę środowiska.</p>
            </div>
          </div>
        </div>

        {/* Sekcja: Call to Action */}
        <div className="mt-20 text-center" data-aos="fade-up">
          <h3 className="text-3xl font-bold text-primary mb-6">
            Powierz opiekę nad swoją farmą fotowoltaiczną profesjonalistom!
          </h3>
          <p className="text-text mb-8">
            Razem zapewnimy niezawodną energię na lata.
          </p>
          <button 
            onClick={handleContactClick}
            className="px-8 py-3 bg-accent text-white font-semibold rounded-lg hover:bg-accent-hover transition-colors duration-300"
          >
            Skontaktuj się z nami
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;