import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';

// Import komponentów layout
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

// Import sekcji
import Header from './components/sections/Header';
import About from './components/sections/About';
import Services from './components/sections/Services';
import Clients from './components/sections/Clients';
import FAQSection from './components/sections/FAQSection';
import Contact from './components/sections/Contact';

// Import stron
import AboutPage from './pages/AboutPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ElectricalMeasurements from './pages/services/ElectricalMeasurements';
import TechnicalDocumentation from './pages/services/TechnicalDocumentation';
import ElectricalInstallations from './pages/services/ElectricalInstallations';
import PVService from './pages/services/PVService';
import SecuritySystems from './pages/services/SecuritySystems';
import CCTVTowers from './pages/services/CCTVTowers';

// Komponent zawierający wszystkie sekcje strony głównej
const HomePage = () => (
  <>
    <Header />
    <About />
    <Services />
    <Clients />
    <FAQSection />
    <Contact />
  </>
);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Inicjalizacja animacji AOS
    AOS.init({
      duration: 1000,
      once: false,
      mirror: true,
      offset: 100,
      easing: 'ease-in-out-cubic'
    });

    // Obsługa scrollowania
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    const timer = setTimeout(() => setIsLoading(false), 1500);

    // Cleanup effect
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  // ScrollToTop button handler
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="App">
        {/* Loader */}
        {isLoading && (
          <div className="loader-wrapper">
            <div className="loader" />
          </div>
        )}

        {/* Navigation */}
        <Navbar isScrolled={isScrolled} />

        {/* Routes */}
        <Routes>
          {/* Strona główna */}
          <Route path="/" element={<HomePage />} />

          {/* Strony informacyjne */}
          <Route path="/o-nas" element={<AboutPage />} />
          <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />

          {/* Usługi */}
          <Route path="/uslugi">
            <Route path="pomiary-elektryczne" element={<ElectricalMeasurements />} />
            <Route path="dokumentacja" element={<TechnicalDocumentation />} />
            <Route path="instalacje-elektryczne" element={<ElectricalInstallations />} />
            <Route path="serwis-pv" element={<PVService />} />
            <Route path="zabezpieczenia" element={<SecuritySystems />} />
            <Route path="wieze-cctv" element={<CCTVTowers />} />
          </Route>
        </Routes>

        {/* ScrollToTop Button */}
        <button
          onClick={handleScrollToTop}
          aria-label="Przewiń do góry strony"
          className={`fixed bottom-8 right-8 bg-accent w-12 h-12 rounded-full flex items-center justify-center text-white transition-all duration-300 hover:bg-primary hover:-translate-y-1 z-50 ${
            isScrolled ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          <i className="fas fa-arrow-up" aria-hidden="true" />
        </button>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;