const AboutUs = () => {
  const badges = [
    {
      icon: "fas fa-certificate",
      text: "Certyfikowani Specjaliści"
    },
    {
      icon: "fas fa-clock",
      text: "24/7 Wsparcie"
    },
    {
      icon: "fas fa-bolt",
      text: "Szybkie terminy realizacji"
    },
    {
      icon: "fas fa-tags",
      text: "Konkurencyjne ceny"
    }
  ];

  return (
    <section className="py-12 bg-light" id="o-nas">
      <div className="container mx-auto px-8">
        <h2 className="section-title text-center text-primary mb-8" data-aos="fade-up">
          Gwarancja Najwyższej Jakości
        </h2>
        <div className="flex flex-col md:flex-row justify-center gap-8 flex-wrap mt-8">
          {badges.map((badge, index) => (
            <div
              key={index}
              className="flex items-center gap-4 px-8 py-4 bg-white rounded-full shadow hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 animate-float"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <i className={`${badge.icon} text-2xl text-accent`}></i>
              <span className="font-semibold text-primary">{badge.text}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;