import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const links = {
    company: [
      { name: 'O nas', href: '/o-nas' },
      { name: 'Usługi', href: '#uslugi' },
      { name: 'Zaufali Nam', href: '#klienci' },
      { name: 'FAQ', href: '#faq' },
      { name: 'Kontakt', href: '#kontakt' },
      { name: 'Polityka Prywatności', href: '/polityka-prywatnosci' }
    ],
    contact: [
      { name: '+48 736 353 906', href: 'tel:+48736353906', icon: 'fas fa-phone' },
      { name: '+48 736 353 907', href: 'tel:+48736353907', icon: 'fas fa-phone' },
      { name: 'biuro@farmserwis.pl', href: 'mailto:biuro@farmserwis.pl', icon: 'fas fa-envelope' },
      { name: 'Cała Polska', href: '#', icon: 'fas fa-map-marker-alt' }
    ],
    social: [
      { name: 'LinkedIn', href: '#', icon: 'fab fa-linkedin' },
      { name: 'Instagram', href: 'https://instagram.com/farmserwis', icon: 'fab fa-instagram' }
    ]
  };

  const renderLink = (link) => {
    // Sprawdź, czy link zaczyna się od '/' (wewnętrzny routing)
    if (link.href.startsWith('/')) {
      return (
        <Link
          to={link.href}
          className="text-white/70 hover:text-accent transition-colors duration-300"
        >
          {link.name}
        </Link>
      );
    }
    // Dla linków zewnętrznych i anchors (#)
    return (
      <a
        href={link.href}
        className="text-white/70 hover:text-accent transition-colors duration-300"
        {...(link.href.startsWith('http') ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        {link.name}
      </a>
    );
  };

  return (
    <footer className="bg-primary text-white pt-8 pb-4">
      <div className="container mx-auto px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6 justify-items-center">
          {/* Logo i opis */}
          <div className="max-w-xs text-center md:text-left">
            <Link to="/" className="flex items-center gap-3 text-2xl font-bold mb-3 justify-center md:justify-start">
              <i className="fas fa-solar-panel text-accent"></i>
              FARMSERWIS
            </Link>
            <p className="text-white/70 mb-3">
              Dostarczamy najwyższej klasy usługi serwisowe dla farm fotowoltaicznych, 
              wykorzystując wieloletnie doświadczenie oraz zaawansowane technologie.
            </p>
          </div>

          {/* Linki firmowe */}
          <div className="max-w-xs text-center md:text-left">
            <h3 className="text-lg font-bold mb-3">Firma</h3>
            <ul className="space-y-1">
              {links.company.map((link, index) => (
                <li key={index}>
                  {renderLink(link)}
                </li>
              ))}
            </ul>
          </div>

          {/* Kontakt */}
          <div className="max-w-xs text-center md:text-left">
            <h3 className="text-lg font-bold mb-3">Kontakt</h3>
            <ul className="space-y-1">
              {links.contact.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.href}
                    className="text-white/70 hover:text-accent transition-colors duration-300 flex items-center gap-2 justify-center md:justify-start"
                  >
                    <i className={`${link.icon} text-accent`} aria-hidden="true"></i>
                    <span>{link.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Social Media */}
          <div className="max-w-xs text-center md:text-left">
            <h3 className="text-lg font-bold mb-3">Social Media</h3>
            <div className="flex flex-col space-y-1">
              {links.social.map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/70 hover:text-accent transition-colors duration-300 flex items-center gap-2 justify-center md:justify-start"
                >
                  <i className={`${social.icon} text-accent`} aria-hidden="true"></i>
                  <span>{social.name}</span>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Prawa autorskie */}
        <div className="text-center text-white/50 pt-4 border-t border-white/10">
          <p>© {currentYear} Farmserwis. Wszystkie prawa zastrzeżone.</p>
          <p>Stworzone przez <a 
            href="https://brightweb.pl" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-accent hover:text-white transition-colors duration-300"
          >
            BrightWeb.pl
          </a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
