import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Przewijanie na górę strony przy montowaniu komponentu
    window.scrollTo(0, 0);
  }, []);

  // Komponent dla sekcji polityki
  const PolicySection = ({ title, children, id }) => (
    <section id={id} className="mb-12">
      <h2 className="text-2xl font-bold text-primary mb-6 flex items-center gap-3">
        <span className="w-1.5 h-8 bg-accent rounded-full"></span>
        {title}
      </h2>
      <div className="space-y-4 text-text">{children}</div>
    </section>
  );

  // Komponenty dla list
  const BulletList = ({ items }) => (
    <ul className="list-disc pl-6 space-y-2 text-text">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );

  const numberedBrowsers = [
    'Edge',
    'Internet Explorer',
    'Chrome',
    'Safari',
    'Firefox',
    'Opera'
  ];

  const mobileBrowsers = [
    'Android',
    'Safari (iOS)',
    'Windows Phone'
  ];

  return (
    <div className="min-h-screen bg-light">
      {/* Hero section */}
      <div className="bg-primary text-white py-20 mt-16">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-6" data-aos="fade-up">
            Polityka Prywatności
          </h1>
          <p className="text-lg text-white/80 max-w-3xl" data-aos="fade-up" data-aos-delay="100">
            Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat,
            w tym danych osobowych oraz ciasteczek, czyli tzw. cookies.
          </p>
        </div>
      </div>

      {/* Główna treść */}
      <div className="container mx-auto px-4 md:px-8 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Sidebar z nawigacją */}
          <aside className="lg:col-span-1">
            <div className="sticky top-24 bg-white rounded-xl shadow-lg p-6">
              <h2 className="text-lg font-bold text-primary mb-4">Spis treści</h2>
              <nav className="space-y-2">
                {Array.from({length: 9}, (_, i) => i + 1).map((num) => (
                  <a
                    key={num}
                    href={`#section-${num}`}
                    className="block text-text hover:text-accent transition-colors duration-300"
                  >
                    {num}. {sections[num - 1]}
                  </a>
                ))}
              </nav>
            </div>
          </aside>

          {/* Treść polityki */}
          <div className="lg:col-span-3">
            <PolicySection id="section-1" title="1. Informacje ogólne">
              <p>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: farmserwis.pl</p>
              <p>Operatorem serwisu oraz Administratorem danych osobowych jest: FARMSERWIS ul. Hawelańska 7/25, 61-625 Poznań</p>
              <p>Adres kontaktowy poczty elektronicznej operatora: biuro@farmserwis.pl</p>
              <p>Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.</p>
              <p>Serwis wykorzystuje dane osobowe w następujących celach:</p>
              <BulletList items={[
                'Obsługa zapytań przez formularz',
                'Prezentacja oferty lub informacji'
              ]} />
            </PolicySection>

            <PolicySection id="section-2" title="2. Wybrane metody ochrony danych">
              <p>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). 
                 Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika 
                 i mogą być odczytane jedynie na docelowym serwerze.</p>
              <p>W celu ochrony danych Operator regularnie wykonuje kopie bezpieczeństwa.</p>
            </PolicySection>

            <PolicySection id="section-3" title="3. Hosting">
              <p>Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: Vercel</p>
              <p>Firma hostingowa w celu zapewnienia niezawodności technicznej prowadzi logi na poziomie serwera. Zapisowi mogą podlegać:</p>
              <BulletList items={[
                'zasoby określone identyfikatorem URL (adresy żądanych zasobów – stron, plików)',
                'czas nadejścia zapytania',
                'czas wysłania odpowiedzi',
                'nazwę stacji klienta – identyfikacja realizowana przez protokół HTTP',
                'informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP',
                'adres URL strony poprzednio odwiedzanej przez użytkownika (referer link)',
                'informacje o przeglądarce użytkownika',
                'informacje o adresie IP'
              ]} />
            </PolicySection>

            <PolicySection id="section-4" title="4. Twoje prawa">
              <p>Przysługuje Ci prawo żądania od Administratora:</p>
              <BulletList items={[
                'dostępu do danych osobowych Ciebie dotyczących',
                'ich sprostowania',
                'usunięcia',
                'ograniczenia przetwarzania',
                'oraz przenoszenia danych'
              ]} />
              <p className="mt-4">Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, 
                 ul. Stawki 2, 00-193 Warszawa.</p>
            </PolicySection>

            <PolicySection id="section-5" title="5. Informacje w formularzach">
              <p>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.</p>
              <p>Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).</p>
              <p>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza.</p>
            </PolicySection>

            <PolicySection id="section-6" title="6. Logi Administratora">
              <p>Informacje o zachowaniu użytkowników w serwisie mogą podlegać logowaniu. 
                 Dane te są wykorzystywane w celu administrowania serwisem.</p>
            </PolicySection>

            <PolicySection id="section-7" title="7. Istotne techniki marketingowe">
              <p>Operator stosuje analizę statystyczną ruchu na stronie poprzez Google Analytics. 
                 Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje.</p>
              <p>Operator stosuje techniki remarketingowe oraz rozwiązania automatyzujące działanie Serwisu 
                 w odniesieniu do użytkowników.</p>
            </PolicySection>

            <PolicySection id="section-8" title="8. Informacja o plikach cookies">
              <p>Serwis korzysta z plików cookies.</p>
              <p>Pliki cookies (tzw. „ciasteczka") stanowią dane informatyczne, w szczególności pliki tekstowe, 
                 które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania 
                 ze stron internetowych Serwisu.</p>
              <p>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies:</p>
              <BulletList items={[
                '"sesyjne" (session cookies) - pliki tymczasowe przechowywane do wylogowania lub opuszczenia strony',
                '"stałe" (persistent cookies) - przechowywane przez określony czas lub do usunięcia przez użytkownika'
              ]} />
            </PolicySection>

            <PolicySection id="section-9" title="9. Zarządzanie plikami cookies">
              <p>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki.</p>
              <p className="font-semibold mt-4 mb-2">Popularne przeglądarki:</p>
              <BulletList items={numberedBrowsers} />
              <p className="font-semibold mt-4 mb-2">Urządzenia mobilne:</p>
              <BulletList items={mobileBrowsers} />
            </PolicySection>
          </div>
        </div>
      </div>

      {/* Sekcja CTA */}
      <div className="bg-white py-12">
        <div className="container mx-auto px-4 md:px-8 text-center">
          <h2 className="text-2xl font-bold text-primary mb-6">Masz pytania dotyczące swojej prywatności?</h2>
          <Link
            to="/#kontakt"
            className="inline-flex items-center gap-2 px-6 py-3 bg-accent text-white font-semibold rounded-lg 
                     hover:bg-accent-hover transition-colors duration-300"
          >
            Skontaktuj się z nami
            <i className="fas fa-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

// Tablica z tytułami sekcji do nawigacji
const sections = [
  'Informacje ogólne',
  'Wybrane metody ochrony danych',
  'Hosting',
  'Twoje prawa',
  'Informacje w formularzach',
  'Logi Administratora',
  'Istotne techniki marketingowe',
  'Informacja o plikach cookies',
  'Zarządzanie plikami cookies'
];

export default PrivacyPolicy;