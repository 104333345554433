import React from 'react';
import { useNavigate } from 'react-router-dom';

// Komponent Hero sekcji
const Hero = ({ title, subtitle, backgroundImage }) => (
  <div className="relative text-white py-20 mt-16">
    <div 
      className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    />
    <div className="absolute inset-0 bg-black/60 z-0" />
    
    <div className="container mx-auto px-4 relative z-10">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 animate-fade-in">
          {title}
        </h1>
        <p className="text-xl text-gray-100 leading-relaxed">
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

// Komponent karty usługi
const ServiceCard = ({ title, items }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <h3 className="text-xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className="fas fa-file-alt text-emerald-500" />
      {title}
    </h3>
    <ul className="space-y-3">
      {items.map((item, idx) => (
        <li key={idx} className="flex items-start text-gray-700 group">
          <span className="inline-block w-1.5 h-1.5 rounded-full bg-emerald-500 mt-2 mr-3 group-hover:scale-125 transition-transform" />
          <span className="flex-1">{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

// Główny komponent
const TechnicalDocumentation = () => {
  const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate('/#kontakt');
    setTimeout(() => {
      document.getElementById('kontakt')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const services = [
    {
      title: "Dokumentacja lokalizacyjna",
      items: [
        "Warunki zabudowy",
        "Decyzje środowiskowe",
        "Analiza planów miejscowych",
        "Optymalizacja lokalizacji",
        "Uzyskiwanie zgód i pozwoleń"
      ]
    },
    {
      title: "Przyłączenie do sieci",
      items: [
        "Wnioski o warunki przyłączenia",
        "Dokumentacja techniczna",
        "Negocjacje umów przyłączeniowych",
        "Współpraca z operatorami sieci",
        "Optymalizacja warunków technicznych"
      ]
    },
    {
      title: "Pozwolenia i odbiory",
      items: [
        "Projekty budowlane",
        "Projekty wykonawcze",
        "Pozwolenia na budowę",
        "Pozwolenia na użytkowanie",
        "Koordynacja odbiorów"
      ]
    }
  ];

  return (
    <div className="bg-gray-50">
      <Hero 
        title="Dokumentacja Techniczno-Prawna"
        subtitle="Kompleksowa obsługa dokumentacji technicznej i prawnej dla farm fotowoltaicznych, na każdym etapie realizacji inwestycji."
        backgroundImage="https://i.imgur.com/8bm8HkV.jpeg"
      />

      <div className="container mx-auto px-4 py-16">
        {/* Introduction */}
        <div className="max-w-3xl mx-auto mb-16">
          <p className="text-gray-700 text-lg text-justify leading-relaxed">
            Świadczymy kompleksową obsługę dokumentacji techniczno-prawnej dla farm fotowoltaicznych, 
            zapewniając profesjonalne wsparcie na każdym etapie realizacji inwestycji. Nasz zespół 
            ekspertów dba o uproszczenie procesu formalno-prawnego oraz gwarantuje przygotowanie 
            precyzyjnej dokumentacji, w pełni zgodnej z obowiązującymi przepisami i normami branżowymi.
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        {/* Experience Section */}
        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-xl shadow-lg p-8 mb-16">
            <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center gap-3">
              <i className="fas fa-star text-emerald-500" />
              Nasze doświadczenie
            </h2>
            <div className="space-y-4 text-gray-700 leading-relaxed">
              <p>
                Nasz zespół składa się z ekspertów w dziedzinie prawa budowlanego, energetycznego 
                i środowiskowego, a nasze doświadczenie obejmuje realizację projektów farm fotowoltaicznych 
                różnej wielkości. Działamy kompleksowo i terminowo, zapewniając pełne wsparcie na każdym 
                etapie inwestycji.
              </p>
              <p>
                Dodatkowo oferujemy wsparcie w przygotowaniu dokumentacji operacyjnej i prawnej niezbędnej 
                do funkcjonowania farmy, w tym regulaminów technicznych, instrukcji eksploatacji, umów 
                dzierżawy gruntów oraz umów na dostawy energii elektrycznej.
              </p>
            </div>
          </div>

          {/* Call to Action */}
          <div className="text-center">
            <p className="text-gray-700 mb-8 text-lg">
              Zapraszamy do kontaktu w celu uzyskania szczegółowej oferty i indywidualnej wyceny naszych usług. 
              Dzięki współpracy z nami możesz mieć pewność, że Twoja inwestycja znajduje się w dobrych rękach.
            </p>
            <button
              onClick={handleContactClick}
              className="px-8 py-4 bg-emerald-500 text-white font-semibold rounded-full 
                       hover:bg-emerald-600 transition-all duration-300 transform hover:-translate-y-1
                       focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              Skontaktuj się z nami
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalDocumentation;