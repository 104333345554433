import React from 'react';
import { useNavigate } from 'react-router-dom';

// Komponent Hero sekcji
const Hero = ({ title, subtitle, backgroundImage }) => (
  <div className="relative text-white py-20 mt-16">
    <div 
      className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    />
    <div className="absolute inset-0 bg-black/60 z-0" />
    
    <div className="container mx-auto px-4 relative z-10">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 animate-fade-in">
          {title}
        </h1>
        <p className="text-xl text-gray-100 leading-relaxed">
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

// Komponent karty usługi
const ServiceCard = ({ title, icon, items }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <h3 className="text-xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className={`fas fa-${icon} text-emerald-500`} />
      {title}
    </h3>
    <ul className="space-y-3">
      {items.map((item, idx) => (
        <li key={idx} className="flex items-start text-gray-700 group">
          <span className="inline-block w-1.5 h-1.5 rounded-full bg-emerald-500 mt-2 mr-3 group-hover:scale-125 transition-transform" />
          <span className="flex-1">{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

// Komponent sekcji informacyjnej
const InfoSection = ({ title, children }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300">
    <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className="fas fa-wrench text-emerald-500" />
      {title}
    </h2>
    <div className="space-y-4 text-gray-700 leading-relaxed">
      {children}
    </div>
  </div>
);

const PVService = () => {
  const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate('/#kontakt');
    setTimeout(() => {
      document.getElementById('kontakt')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const services = [
    {
      title: "Serwis DC",
      icon: "bolt",
      items: [
        "Kontrola napięcia i prądu w stringach",
        "Naprawa uszkodzonych połączeń",
        "Weryfikacja sprawności kabli DC",
        "Testy paneli fotowoltaicznych",
        "Optymalizacja wydajności"
      ]
    },
    {
      title: "Serwis AC i inwertery",
      icon: "plug",
      items: [
        "Konserwacja falowników",
        "Kontrola zabezpieczeń",
        "Aktualizacja oprogramowania",
        "Serwis rozdzielnic",
        "Przegląd uziemień"
      ]
    },
    {
      title: "SmartLogger i CCTV",
      icon: "desktop",
      items: [
        "Konfiguracja SmartLoggera",
        "Monitoring w czasie rzeczywistym",
        "Serwis systemu CCTV",
        "Diagnostyka błędów",
        "Optymalizacja parametrów"
      ]
    }
  ];

  return (
    <div className="bg-gray-50">
      <Hero 
        title="Serwis PV"
        subtitle="Profesjonalny serwis farm fotowoltaicznych zapewniający sprawność, 
                 niezawodność oraz bezpieczeństwo instalacji."
        backgroundImage="https://i.imgur.com/DarmVl2.jpeg"
      />

      <div className="container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto mb-16">
          <p className="text-gray-700 text-lg text-justify leading-relaxed">
            Oferujemy profesjonalny serwis farm fotowoltaicznych, zapewniając sprawność, 
            niezawodność oraz bezpieczeństwo działania instalacji. Nasza oferta obejmuje pełen zakres 
            usług serwisowych, w tym obsługę strony AC i DC, inwerterów, SmartLoggerów oraz systemów 
            monitoringu CCTV.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="max-w-3xl mx-auto">
          <InfoSection title="Kompleksowa obsługa">
            <p>
              W ramach diagnostyki i naprawy awarii oferujemy szybkie i skuteczne usuwanie problemów, 
              korzystając z zaawansowanych narzędzi diagnostycznych do precyzyjnego lokalizowania 
              usterek. Zapewniamy priorytetowy czas reakcji oraz wsparcie techniczne.
            </p>
            <p>
              Po każdym serwisie dostarczamy szczegółowy raport techniczny, który zawiera wyniki 
              przeprowadzonych testów, stan instalacji oraz rekomendacje dotyczące dalszych działań. 
              Nasza dokumentacja jest zgodna z wymaganiami lokalnych przepisów i standardów branżowych.
            </p>
          </InfoSection>

          <div className="text-center mt-16">
            <p className="text-gray-700 mb-8 text-lg">
              Zapraszamy do kontaktu, aby uzyskać szczegółową ofertę i indywidualną wycenę naszych 
              usług. Dzięki naszemu zaangażowaniu Twoja farma fotowoltaiczna będzie działać 
              niezawodnie i wydajnie przez wiele lat.
            </p>
            <button
              onClick={handleContactClick}
              className="px-8 py-4 bg-emerald-500 text-white font-semibold rounded-full 
                       hover:bg-emerald-600 transition-all duration-300 transform hover:-translate-y-1
                       focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              Skontaktuj się z nami
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PVService;