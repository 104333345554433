import React from 'react';
import { useNavigate } from 'react-router-dom';

// Komponent Hero sekcji
const Hero = ({ title, subtitle, backgroundImage }) => (
  <div className="relative text-white py-20 mt-16">
    <div 
      className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    />
    <div className="absolute inset-0 bg-black/60 z-0" />
    
    <div className="container mx-auto px-4 relative z-10">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 animate-fade-in">
          {title}
        </h1>
        <p className="text-xl text-gray-100 leading-relaxed">
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

// Komponent karty usługi
const ServiceCard = ({ title, icon, items }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <h3 className="text-xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className={`fas fa-${icon} text-emerald-500`} />
      {title}
    </h3>
    <ul className="space-y-3">
      {items.map((item, idx) => (
        <li key={idx} className="flex items-start text-gray-700 group">
          <span className="inline-block w-1.5 h-1.5 rounded-full bg-emerald-500 mt-2 mr-3 group-hover:scale-125 transition-transform" />
          <span className="flex-1">{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

// Komponent sekcji informacyjnej
const InfoSection = ({ title, children }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300">
    <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className="fas fa-info-circle text-emerald-500" />
      {title}
    </h2>
    <div className="space-y-4 text-gray-700 leading-relaxed">
      {children}
    </div>
  </div>
);

const CCTVTowers = () => {
  const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate('/#kontakt');
    setTimeout(() => {
      document.getElementById('kontakt')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const services = [
    {
      title: "Wyposażenie techniczne",
      icon: "camera", // ikona kamery dla wyposażenia technicznego
      items: [
        "Kamery HD i 4K wysokiej rozdzielczości",
        "Funkcja noktowizji",
        "Zaawansowana analiza obrazu",
        "Detekcja ruchu w czasie rzeczywistym",
        "System rozpoznawania twarzy"
      ]
    },
    {
      title: "Autonomiczne działanie",
      icon: "solar-panel", // ikona panelu słonecznego dla autonomicznego działania
      items: [
        "Panele fotowoltaiczne",
        "Wbudowane akumulatory",
        "Niezależność energetyczna",
        "Działanie 24/7",
        "Odporność na warunki atmosferyczne"
      ]
    },
    {
      title: "Zarządzanie systemem",
      icon: "desktop", // ikona komputera dla zarządzania systemem
      items: [
        "Zdalne sterowanie",
        "Monitoring w czasie rzeczywistym",
        "Integracja z chmurą",
        "Dostęp przez urządzenia mobilne",
        "Analiza zapisów wideo"
      ]
    }
  ];

  return (
    <div className="bg-gray-50">
      <Hero 
        title="Mobilne Wieże CCTV"
        subtitle="Profesjonalne rozwiązania dla monitoringu tymczasowego i stałego farm fotowoltaicznych, 
                 dostępne w opcji wynajmu oraz zakupu."
        backgroundImage="https://i.imgur.com/2EoeP04.jpeg"
      />

      <div className="container mx-auto px-4 py-16">
        {/* Introduction */}
        <div className="max-w-3xl mx-auto mb-16">
          <p className="text-gray-700 text-lg text-justify leading-relaxed">
            Oferujemy profesjonalne usługi wynajmu oraz sprzedaży mobilnych wież CCTV, które są 
            nowoczesnym i skutecznym rozwiązaniem dla monitoringu tymczasowego i stałego farm 
            fotowoltaicznych. Nasze mobilne wieże CCTV zapewniają niezawodną ochronę przed 
            kradzieżami, wandalizmem i innymi zagrożeniami. Dzięki swojej konstrukcji są łatwe do 
            transportu i instalacji.
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="max-w-3xl mx-auto">
          {/* Additional Information */}
          <InfoSection title="Elastyczne opcje współpracy">
            <p>
              Nasza oferta obejmuje wynajem wież na określony czas oraz sprzedaż własnościową. 
              Wynajem to idealne rozwiązanie dla projektów tymczasowych, takich jak budowa farmy, 
              natomiast sprzedaż gwarantuje klientom długoterminowe korzyści z posiadania własnego 
              systemu ochrony.
            </p>
            <p>
              Dodatkowo oferujemy pełne wsparcie techniczne, szkolenia z obsługi oraz serwisowanie 
              urządzeń, aby zapewnić ich niezawodność i wydajność. Wykonujemy także rozbudowę 
              systemów o nowe funkcje w miarę zmieniających się potrzeb klienta.
            </p>
          </InfoSection>

          {/* Call to Action */}
          <div className="text-center mt-16">
            <p className="text-gray-700 mb-8 text-lg">
              Skontaktuj się z nami, aby uzyskać szczegółowe informacje, indywidualną wycenę oraz 
              profesjonalne wsparcie na każdym etapie współpracy. Dzięki naszym usługom Twoja farma 
              fotowoltaiczna będzie skutecznie chroniona przez cały czas.
            </p>
            <button
              onClick={handleContactClick}
              className="px-8 py-4 bg-emerald-500 text-white font-semibold rounded-full 
                       hover:bg-emerald-600 transition-all duration-300 transform hover:-translate-y-1
                       focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              Skontaktuj się z nami
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCTVTowers;