import React from 'react';
import { useNavigate } from 'react-router-dom';

// Komponent Hero sekcji
const Hero = ({ title, subtitle, backgroundImage }) => (
  <div className="relative text-white py-20 mt-16">
    <div 
      className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    />
    <div className="absolute inset-0 bg-black/60 z-0" />
    
    <div className="container mx-auto px-4 relative z-10">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 animate-fade-in">
          {title}
        </h1>
        <p className="text-xl text-gray-100 leading-relaxed">
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

// Komponent karty usługi
const ServiceCard = ({ title, icon, items }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <h3 className="text-xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className={`fas fa-${icon} text-emerald-500`} />
      {title}
    </h3>
    <ul className="space-y-3">
      {items.map((item, idx) => (
        <li key={idx} className="flex items-start text-gray-700 group">
          <span className="inline-block w-1.5 h-1.5 rounded-full bg-emerald-500 mt-2 mr-3 group-hover:scale-125 transition-transform" />
          <span className="flex-1">{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

// Komponent sekcji informacyjnej
const InfoSection = ({ title, children }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-all duration-300">
    <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center gap-3">
      <i className="fas fa-info-circle text-emerald-500" />
      {title}
    </h2>
    <div className="space-y-4 text-gray-700 leading-relaxed">
      {children}
    </div>
  </div>
);

const ElectricalInstallations = () => {
  const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate('/#kontakt');
    setTimeout(() => {
      document.getElementById('kontakt')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const services = [
    {
      title: "Instalacje DC",
      icon: "bolt", // ikona dla instalacji DC
      items: [
        "Montaż i połączenie modułów w stringi",
        "Profesjonalne okablowanie DC",
        "Weryfikacja połączeń",
        "Minimalizacja strat energii",
        "Systemy zabezpieczeń DC"
      ]
    },
    {
      title: "Instalacje AC",
      icon: "plug", // ikona dla instalacji AC
      items: [
        "Montaż i konfiguracja falowników",
        "Integracja z rozdzielnicą główną",
        "Podłączenie do sieci energetycznej",
        "Systemy uziemienia",
        "Połączenia wyrównawcze"
      ]
    },
    {
      title: "Zarządzanie i monitoring",
      icon: "desktop", // ikona dla zarządzania
      items: [
        "Konfiguracja systemów zarządzania",
        "Monitoring wydajności instalacji",
        "Zdalny dostęp do systemu",
        "Analiza parametrów operacyjnych",
        "Systemy wczesnego ostrzegania"
      ]
    }
  ];

  return (
    <div className="bg-gray-50">
      <Hero 
        title="Instalacje Elektryczne"
        subtitle="Profesjonalne instalacje elektryczne dla farm fotowoltaicznych, 
                 obejmujące systemy AC, DC oraz zaawansowane rozwiązania monitoringu."
        backgroundImage="https://i.imgur.com/jnnYqLL.jpeg"
      />

      <div className="container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto mb-16">
          <p className="text-gray-700 text-lg text-justify leading-relaxed">
            Zapewniamy kompleksowe i profesjonalne usługi w zakresie instalacji elektrycznych dla farm 
            fotowoltaicznych, obejmujące podłączenia po stronie AC i DC, konfigurację systemów oraz 
            zapewnienie zdalnego dostępu do zarządzania instalacją. Nasze działania skupiają się na 
            dostarczeniu niezawodnych rozwiązań, które gwarantują optymalną wydajność, bezpieczeństwo 
            oraz pełną zgodność z obowiązującymi normami.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="max-w-3xl mx-auto">
          <InfoSection title="Nasze doświadczenie">
            <p>
              Nasze doświadczenie w realizacji instalacji elektrycznych dla farm solarnych pozwala na 
              dostarczenie rozwiązań o najwyższej jakości, niezawodności i zgodności z przepisami. 
              Dzięki zastosowaniu nowoczesnych technologii oraz materiałów najwyższej klasy tworzymy 
              instalacje o maksymalnej wydajności i trwałości.
            </p>
            <p>
              Po zakończeniu prac instalacyjnych przeprowadzamy kompleksowe odbiory techniczne, 
              testując sprawność falowników oraz połączeń AC i DC, weryfikując działanie zabezpieczeń i 
              uziemienia, a także przekazując pełną dokumentację techniczną, w tym protokoły pomiarowe 
              zgodne z wymaganiami lokalnych przepisów.
            </p>
          </InfoSection>

          <div className="text-center mt-16">
            <p className="text-gray-700 mb-8 text-lg">
              Zapraszamy do kontaktu w celu uzyskania szczegółowej oferty i indywidualnej wyceny. 
              Dzięki naszej wiedzy i zaangażowaniu Twoja farma fotowoltaiczna będzie działała 
              bezpiecznie i efektywnie przez wiele lat.
            </p>
            <button
              onClick={handleContactClick}
              className="px-8 py-4 bg-emerald-500 text-white font-semibold rounded-full 
                       hover:bg-emerald-600 transition-all duration-300 transform hover:-translate-y-1
                       focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              Skontaktuj się z nami
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricalInstallations;