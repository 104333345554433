import React from 'react';
import AnimatedCounter from '../../shared/AnimatedCounter';

const Clients = () => {
  const clientTypes = [
    {
      icon: "fas fa-solar-panel",
      title: "Właściciele Farm Fotowoltaicznych",
      description: "Zapewniamy kompleksową obsługę i optymalizację wydajności instalacji, dbając o każdy szczegół techniczny."
    },
    {
      icon: "fas fa-industry",
      title: "Operatorzy Farm O&M",
      description: "Wspieramy w utrzymaniu najwyższych standardów operacyjnych, gwarantując niezawodność systemu."
    },
    {
      icon: "fas fa-hard-hat",
      title: "Firmy Inżynieryjno-budowlane",
      description: "Oferujemy specjalistyczne wsparcie techniczne i konsultacje na każdym etapie realizacji."
    }
  ];

  return (
    <section className="section bg-primary text-white relative overflow-hidden" id="klienci">
      <div className="container mx-auto px-8 relative z-10">
        <h2 className="section-title text-white" data-aos="fade-up">
          Zaufali Nam
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-24">
          {clientTypes.map((client, index) => (
            <div
              key={index}
              className="client-item"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <i className={`${client.icon} text-5xl text-accent mb-6 block animate-float`} />
              <h3 className="text-2xl font-bold text-accent mb-4">{client.title}</h3>
              <p className="text-white/90">{client.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Clients;