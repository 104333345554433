import React from 'react';

const Header = () => {
  return (
    <header className="min-h-screen relative overflow-hidden" style={{ backgroundImage: `url('https://i.imgur.com/4Q26dmC.jpeg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {/* Overlay dla lepszej czytelności tekstu */}
      <div className="absolute inset-0 bg-black bg-opacity-30"></div>

      {/* Upper Section - White */}
      <div className="min-h-[85vh] flex items-center relative z-10">
        <div className="container mx-auto px-4">
          <div className="text-center mb-8 sm:mb-20">
            <div className="inline-block mb-8 sm:mb-12">
              <h2 className="text-lg sm:text-xl tracking-[0.3em] text-white uppercase mb-4 sm:mb-6" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
                Montaż i Serwis Instalacji Fotowoltaicznych
              </h2>
              <div className="text-5xl sm:text-7xl md:text-8xl font-bold tracking-tight" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
                <span className="text-[#22c55e]">FARM</span>
                <span className="text-white">SERWIS</span>
              </div>
              <div className="mt-6 sm:mt-8 text-xl sm:text-2xl md:text-3xl text-white font-light" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
                Profesjonalne wsparcie techniczne
              </div>
            </div>

            <div className="flex flex-wrap justify-center gap-4 sm:gap-8">
              <a 
                href="#uslugi"
                className="group relative px-8 sm:px-12 py-3 sm:py-4 border-2 border-[#22c55e] overflow-hidden"
              >
                <div className="absolute inset-0 w-0 bg-[#22c55e] transition-all duration-500 ease-out group-hover:w-full"></div>
                <span className="relative text-white text-base sm:text-lg font-medium" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}>
                  Oferta
                </span>
              </a>
              
              <a 
                href="#kontakt"
                className="group relative px-8 sm:px-12 py-3 sm:py-4 bg-[#22c55e] overflow-hidden"
              >
                <div className="absolute inset-0 w-0 bg-[#16a34a] transition-all duration-500 ease-out group-hover:w-full"></div>
                <span className="relative text-white text-base sm:text-lg font-medium" style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}>
                  Kontakt
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Lower Section - Dark with Contact */}
      <div className="min-h-[15vh] bg-[#0f172a] bg-opacity-80 relative z-10 flex items-center">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-2 py-4">
            {/* Phone Numbers */}
            <div className="text-center">
              <div className="text-[#22c55e] text-xl mb-1">
                <i className="fas fa-phone"></i>
              </div>
              <div className="text-white text-xs uppercase tracking-[0.2em] mb-1">
                Wsparcie Techniczne
              </div>
              <div className="flex justify-center gap-4">
                <a href="tel:+48736353906" className="text-sm text-white hover:text-[#22c55e] transition-colors">
                  736 353 906
                </a>
                <a href="tel:+48736353907" className="text-sm text-white hover:text-[#22c55e] transition-colors">
                  736 353 907
                </a>
              </div>
            </div>

            {/* Email */}
            <div className="text-center">
              <div className="text-[#22c55e] text-xl mb-1">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="text-white text-xs uppercase tracking-[0.2em] mb-1">
                Email
              </div>
              <a href="mailto:biuro@farmserwis.pl" className="block text-sm text-white hover:text-[#22c55e] transition-colors">
                biuro@farmserwis.pl
              </a>
            </div>

            {/* Instagram Section */}
            <div className="text-center sm:col-span-2 md:col-span-1">
              <div className="text-[#22c55e] text-xl mb-1">
                <i className="fab fa-instagram"></i>
              </div>
              <div className="text-white text-xs uppercase tracking-[0.2em] mb-1">
                Obserwuj nas
              </div>
              <a href="https://instagram.com/farmserwis" target="_blank" rel="noopener noreferrer" className="block text-sm text-white hover:text-[#22c55e] transition-colors">
                @farmserwis
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
